import { useEffect, useState } from "react";
import "./VideoList.scss";
import background from "../../../images/bg-2.jpg";
import flower from "../../../images/flower-final.png";
import SingleVideo from "./SingleVideo/SingleVideo";
import divider from "../../../images/divider-icon.png";
import { Tab } from "bootstrap";
import { Tabs } from "react-bootstrap";
import VideoCustomSlider from "./VideoCustomSlider/VideoCustomSlider";

export default function VideoList({ videos, title, gotoEpisode  }) {

  return (
    <div style={{ backgroundImage: `url(${background})`, backgroundRepeat:'round', backgroundSize:'cover' }}>
      <div className="video-section-content">
        <div className="slider-heading-middle">
          <div
            style={{
              backgroundImage: `url(${flower})`
            }}
            className="flower"
          >
            <h2> {title} </h2>
            <img src={divider} />
          </div>
        </div>

        <VideoCustomSlider gotoEpisode={gotoEpisode} items={videos} />



      </div>
    </div>
  );
}
