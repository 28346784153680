import { useEffect, useState, useRef } from "react";
import ShowCast from "../ShowCast/ShowCast";
import "./SynopsisSection.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpLong } from "@fortawesome/free-solid-svg-icons";


export default function SynopsisSection({
  urdu,
  synopsis,
  directors,
  writers,
  producers,
  actors,
  hosts,
  contentID
}) {

  const engRef = useRef(null)
  const urduRef = useRef(null)
  const urduRef2 = useRef(null)
  const castRef = useRef(null)

  const [ showSynopsisEng, setShowSynopsisEng] = useState(true);
  const [ synEngHeight, setSynEngHeight] = useState(0);
  const [ showSynopsisUrdu, setShowSynopsisUrdu] = useState(true);
  const [ showSynopsisUrduHeight, setShowSynopsisUrduHeight] = useState(0);
  const [ showCastMoreLess, setShowCastMoreLess] = useState(true);
  const [ showcastHeight, setShowcastHeight] = useState(0);
  const [ firstLoad, setFirstLoad ] = useState(true);
  const [ isMobile, setIsmobile ] = useState(false);
  const [ hideShowEng, setHideShowEng ] = useState(true);
  const [ hideShowUrdu, setHideShowUrdu ] = useState(true);
  const [ hideShowCast, setHideShowCast ] = useState(true);


  function opencloseEngSynopsis(){
    setShowSynopsisEng(!showSynopsisEng)
  }

  function opencloseCastSynopsis(){
    setShowCastMoreLess(!showCastMoreLess)
  }

  function opencloseUrduSynopsis(){
    setShowSynopsisUrdu(!showSynopsisUrdu)
  }

  useEffect(() => {
   

    const changeWidth = () => {
      var isMobileScreen;
      if(window.innerWidth > 576){
        setIsmobile(false)
        isMobileScreen = false;
      }else{
        setIsmobile(true)
        isMobileScreen = true;
      }
      setShowSynopsisEng(true);

      setFirstLoad(true);

      setTimeout(() => {

        var heightcus = urduRef.current.clientHeight > engRef.current.clientHeight ? urduRef.current.clientHeight : engRef.current.clientHeight;

        if(isMobileScreen){

          if(heightcus >  40)
          {
            setHideShowEng(false)
          }else{
            setHideShowEng(true)
          }
      
        }else{
          if(heightcus >  50)
          {
            setHideShowEng(false)
          }else{
            setHideShowEng(true)
          }
        }

        setSynEngHeight(heightcus);
        
        setShowSynopsisEng(false);

        setFirstLoad(false);
      }, 300);
    };
    

      setSynEngHeight(engRef.current.clientHeight);

      setShowSynopsisEng(false);

      setFirstLoad(false);



    // window.addEventListener("resize", changeWidth);
    changeWidth()
  },[])


  return (
    <div className="synopsis-section">
      <div className="row">
      <div className="col-md-6 col-sm-6 urdu-half showMobile" >
          <h4>{contentID  == 18 ? 'مرکزی خیال' : 'کہانی'}</h4>
          <p className={ `synopsis-text`} style={{maxHeight: firstLoad ? '100%' : showSynopsisEng ? `${synEngHeight}px` : isMobile ? `52px` : `60px` }} ref={urduRef2}>{urdu}</p>
        </div>
        <div className="col-md-6 col-sm-6">
          <div className="english-half">
            <p className="english-heading">Synopsis</p>
            <div className="english-detail-section" style={{maxHeight: firstLoad ? '100%' : showSynopsisEng ? `${synEngHeight}px` : isMobile ? `38px` : `46px` }} ref={engRef}>
            <p className={ `synopsis-text`}  >{synopsis}</p>
            
            {writers.length > 0 ? (
              <ShowCast name={"Writer"} items={writers} />
            ) : null}
            {directors.length > 0 ? (
              <ShowCast name={"Director"} items={directors} />
            ) : null}
            {producers.length > 0 ? (
              <ShowCast name={"Producer"} items={producers} />
            ) : null}
            {hosts.length > 0 ? <ShowCast name={"Host"} items={hosts} /> : null}
            {actors.length > 0 ? (
              <ShowCast name={"Cast"} items={actors} />
            ) : null}
            </div>
             
          </div>
        </div>
        <div className="col-md-6 col-sm-6 urdu-half hideMobile">
        <h4>{contentID  == 18 ? 'مرکزی خیال' : 'کہانی'}</h4>
          <p className={ `synopsis-text`} style={{maxHeight: firstLoad ? '100%' : showSynopsisEng ? `${synEngHeight}px` : isMobile ? `52px` : `60px` }} ref={urduRef}>{urdu}</p>
        </div>
      </div>

      {
        showSynopsisEng ? <h5 className="detail-text" onClick={opencloseEngSynopsis}><FontAwesomeIcon icon={faUpLong} /></h5> : <div className="detail-btn-section"><div className="detail-btn-text" onClick={opencloseEngSynopsis}>DETAIL</div></div> 
      }
     
    </div>
  );
}
