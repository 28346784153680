import { useEffect, useState, useRef } from "react";
import contactImage from "../../images/Contact-US.jpg";
import background from "../../images/bg-2.jpg";
import flower from "../../images/flower-final.png";
import divider from "../../images/divider-icon.png";
import "./Career.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import { API_URL } from "../../Utils/constants";


// function Iframe(props) {
//   return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
// }

export default function Career() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [showLoading, setShowLoading] = useState(true);
    const [message, setMessage] = useState("");
    const [ showNameError, setShowNameError] = useState(false);
    const [ showEmailError, setShowEmailError] = useState(false);
    const [ showFileError, setShowFileError] = useState(false);
    const [ showCVError, setShowCVError] = useState(false);
    const [ showEmailFormatError, setShowEmailFormatError] = useState(false);
    const [ showPhoneError, setShowPhoneError] = useState(false);
    const [ showError, setShowError] = useState(false);
    const [ showSubmittError, setShowSubmittError] = useState(false);
    const [ sending, setSending] = useState(false);
    const [ submitted, setsubmitted] = useState(false);
    const [ departments, SetDepartments ] = useState([])
    const [ position, SetPosition ] = useState([])
    const [ selectedDepartment, setSelectedDepartment] = useState('')
    const [ selectedPosition, setSelectedPosition] = useState('')
    const [ selectedCVName, setSelectedCVName] = useState('')
    const [ selectedCV, setSelectedCV] = useState(null)

    const myRef = useRef(null)
    const gotoFormTop = () => myRef.current.scrollIntoView()

  useEffect(() => {
    document.title = "Career | AAN TV";
    window.scrollTo(0, 0);
    const depts = [ 'Programming', 'Creative', 'HR', 'Technical', 'Social Media']
    SetDepartments(depts)
    setShowLoading(false)
    allDepartments.map((single, index) => {
        if(index == 0){
            setSelectedDepartment(single.name)
            SetPosition(single.fields);
            setSelectedPosition(single.fields[0])
        }
    })
  }, []);

  const nameChange = (event) => {
    setName(event.target.value);
  };

  const emailChange = (event) => {
    setEmail(event.target.value);
  };

  const phoneChange = (event) => {
    setPhone(event.target.value);
  };



  const allDepartments = [ 
    { 
        'name' : 'Programming',
        'fields' : [ 'Manager', 'Team Lead']},
    { 
        'name' : 'Creative',
        'fields' : [ 'Manager', 'Graphic Designer', 'RTG', 'Animator']},
    { 
        'name' : 'HR',
        'fields' : [ 'Trainee']},
    { 
        'name' : 'Technical',
        'fields' : [ 'Broadcast Manager', 'Broadcast Engr', 'System Support Engr', 'Software Engr']},
    { 
        'name' : 'Social Media',
        'fields' : [ 'Graphic Designer', 'SEO', 'Content Writer']},
  ]





  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  function getSubmit(){
    setShowNameError(false)
    setShowEmailError(false)
    setShowEmailFormatError(false)
    setShowPhoneError(false)
    setShowCVError(false)
    setShowFileError(false)
    setShowSubmittError(false)
    var errorFound = false;
    if(name.trim() == ''){
        setShowNameError(true)
        errorFound = true;
    }
    if(email.trim() == ''){
        setShowEmailError(true)
        errorFound = true;
    }else if(regex.test(email) == false){
        setShowEmailFormatError(true)
        errorFound = true;
    }
    if(phone.trim() == ''){
        setShowPhoneError(true)
        errorFound = true;
    }
    if(!selectedCV){
        setShowCVError(true)
        errorFound = true;
    }


    if(errorFound){
        return;
    }

    setSending(true);
    axios.post(
      API_URL + "/career",
      { name: name,
        email:email,
        phone: phone,
        department: selectedDepartment,
        position: selectedPosition,
        cv: selectedCV
      },
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    ).then(res => {
        if (res.data.code == 200) {
          setsubmitted(true);
          setSending(false)
          gotoFormTop()
        }else{
          setShowSubmittError(true);
          setSending(false);
        }
    }).catch(err => {
      console.log(err)
      setShowSubmittError(true);
      setSending(false);
    })



  }

  const deptChange = (value) => {
    setSelectedDepartment(value.target.value)
    allDepartments.map(single => {
        if(single.name == value.target.value){
            SetPosition(single.fields);
            setSelectedPosition(single.fields[0])
        }
    })

    }

  const positionChange = (value) => {
    setSelectedPosition(value.target.value)
    }



  const fileChange = (event) => {
    setShowFileError(false)
    setShowCVError(false)
    let file = event.target.files[0];
    if(file.size > 2097152){
        setShowFileError(true);
        setSelectedCV(null)
        event.target.value = null;
    }else{
        setSelectedCV(event.target.files[0])
    }
    }


    

  return ( showLoading ? null : 
    <>
        {/* <div className="banner-top-image">
            <img src={contactImage} />
        </div> */}
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: "round",
          backgroundSize: "cover",
        }}
      >
        <div className="contact-us-page" ref={myRef}>
          <div className="slider-heading-middle">
            <div
              style={{
                backgroundImage: `url(${flower})`,
              }}
              className="flower"
            >
              <h2>CAREER</h2>
              <img src={divider} />
            </div>
          </div>

          <div className="row">

          <div className="col-md-6">
          {
            submitted ? (
              <div className="career-form-submitted">
                  <h5>Application submit successfully.</h5>
              </div>  
                ) : showError ? (
                <div />
              ) : (
                <div className="contact-form-section">
                {/* <h6>Or You Can Fill the Application Below</h6> */}
    
                <h6>* Full Name</h6>
                <input type={"text"} value={name} onChange={nameChange} />
                {
                    showNameError ? <p className="error-text">Full Name is required.</p> : null
                }
                <h6>* Email</h6>
                <input type={"email"} value={email} onChange={emailChange} />
                {
                    showEmailError ? <p className="error-text">Email is required.</p> : null
                }
                {
                    showEmailFormatError ? <p className="error-text">Email is not valid.</p> : null
                }
                <h6>* Contact Number</h6>
                <input type={"text"} value={phone} onChange={phoneChange} />
                {
                    showPhoneError ? <p className="error-text">Contact Number is required.</p> : null
                }


                <h6>* Department</h6>
                
                <select className="career-select" value={selectedDepartment} onChange={deptChange} >
                    {
                        departments.map((single, index) => <option key={`dept-${index}`} value={single}>{single}</option>)
                    }
                </select>

                <h6>* Position</h6>
                <select className="career-select" value={selectedPosition} onChange={positionChange} >
                    {
                        position.map((single, index) => <option key={`position-${index}`} value={single}>{single}</option>)
                    }
                </select>

                <h6>* Upload CV</h6>
                <input type="file" name="file" onChange={fileChange} accept=".doc, .docx, .pdf, .jpg, .jpeg" />
                <p>* Maximum size: 2 MB</p>
                {
                    showFileError ? <p className="error-text">Maximum filesize allowed 2 MB.</p> : null
                }
                {
                    showCVError ? <p className="error-text">CV is required.</p> : null
                }

                { showSubmittError ? (
                    <div className="contect-form-submitted-error">
                     <h5>* Application submission failed. Please try again.</h5>
                  </div> 
                ) : null
            }
                {
                    sending ? (
                        <div className="contact-form-btn">
                    <button >SUBMITING</button>
                  </div>
                  ) : (
                    <div className="contact-form-btn">
                    <button onClick={getSubmit}>SUBMIT</button>
                  </div>
                  )
                }
    


              </div>
              )
            
          }
          </div>

          <div className="col-md-6">

          </div>
          </div>



        </div>
      </div>
    </>
  );
}
