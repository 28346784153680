import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../../../Utils/constants";
import ScheduleHome from "../../ScheduleHome/ScheduleHome";
import FeaturedSliders from "../../Sliders/FeaturedSliders/FeaturedSliders";
import ProgramList from "./ProgramList/ProgramList";
import "./ProgramHome.scss";
import Loader from "../../Layout/Loader/Loader";


export default function ProgramHome() {
    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState({});
    const [slider, setSlider] = useState([]);
    const [sliders, setSliders] = useState([]);
    const [schedules, setSchedules] = useState([]);
    
  useEffect(() => {
    document.title = 'AAN TV'
    // document.title = 'Program | AAN TV'
    window.scrollTo(0, 0)

    axios
      .get(API_URL + "/getProgramList")
      .then((res) => {
        setContent(res.data.data);
        var rand = Math.floor(Math.random() * res.data.sliders.length)
        setSlider(res.data.sliders[rand])
        setSliders(res.data.sliders)
        setSchedules(res.data.schedules)
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setLoading]);

  return (
      loading ? <Loader />  :
      <>
      {
          content.length > 0 ? <>

        
          <FeaturedSliders item={slider} items={sliders} />
        
          {/* <div className="program-top-division" /> */}
        
          <ScheduleHome items={schedules} />
        
          <ProgramList programs={content} />


          </> : null
      }

      </>
  );
}