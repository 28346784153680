import { useEffect, useState } from "react";
import "./FeaturedSliders.scss";
import SingleFeaturedSlider from "./SingleFeaturedSlider/SingleFeaturedSlider";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function FeaturedSliders({ item, items }) {
  const [isMobile, setIsmobile] = useState(false);


  useEffect(() => {

    const changeWidth = () => {
      if(window.innerWidth > 576){
        setIsmobile(false)
      }else{
        setIsmobile(true)
      }
    }


    window.addEventListener("resize", changeWidth);
    changeWidth()
  },[])

  return (
    <div className="featured-slider">
      {
        // isMobile ? <Carousel autoPlay={true} infiniteLoop={true} interval={5000} showArrows={false} showStatus={false} showThumbs={false} > 
        // {items.map((single, i) => (
        //   <SingleFeaturedSlider
        //     key={`featured-${i}`}
        //     index={i}
        //     isMobile={isMobile}
        //     item={single}
        //   />
        // ))}
        //  </Carousel> : <SingleFeaturedSlider
        //  isMobile={isMobile}
        //   item={item}
        // />
        <Carousel autoPlay={true} infiniteLoop={true} interval={5000} showArrows={false} showStatus={false} showThumbs={false} > 
        {items.map((single, i) => (
          <SingleFeaturedSlider
            key={`featured-${i}`}
            index={i}
            isMobile={isMobile}
            item={single}
          />
        ))}
         </Carousel>
      }
      {/* <Carousel autoPlay={true} infiniteLoop={true} interval={5000} showArrows={false} showStatus={false} showThumbs={false} > 
      {items.map((item, i) => (
        <SingleFeaturedSlider
          key={`featured-${i}`}
          index={i}
          item={item}
        />
      ))}
       </Carousel> */}
          {/* <SingleFeaturedSlider
          item={item}
        /> */}
    </div>
  );
}
