import { useEffect, useState } from "react";
import "./ShowCast.scss";


export default function ShowCast({items, name}) {

  return (
     <div className="cast-section">
           <p className="cast-section-name">{name}</p><p className="cast-name-divider">:</p>
           <p>{items.map((item,i) => <span key={`cast-`+ i}>{`${item.name} ${i != (items.length - 1) ? ', ' : ''}`}</span>)}</p>
     </div>
  );
}