import { useEffect, useState } from "react";
import "./ContentTopVideo.scss";
import background from "../../../images/bg-3.jpg";
import flower from "../../../images/flower-final.png";
import divider from "../../../images/divider-icon.png";


function Iframe(props) {
  return (<div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }} />);
}

export default function ContentTopVideo({ topVideo, isLive, isEpisode }) {

  return (
    <div style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'round', backgroundSize: 'cover' }}>
      <div className="slider-section-top-drama">
        <div className="slider-heading">
          <div
            style={{
              backgroundImage: `url(${flower})`,

            }}
            className="flower"
          >
            <h2> {isLive ? 'AAN LIVE' : isEpisode ? topVideo.content.title + ' ' + topVideo.title : topVideo.title} </h2>
            <img src={divider} />
          </div>
        </div>
        <div className="video-player-section-full">
          <div className="video-player-section">
            <Iframe iframe={isLive ? topVideo.embed : topVideo.detail} />
            {/* {topVideo.detail} */}
          </div>
        </div>


      </div>
    </div>
  );
}
