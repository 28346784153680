import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./BlogGridSingle.scss";

export default function BlogGridSingle({ blog, index }) {

  return (
      <div className={`blog-single-grid ${index < 2 ? 'col-md-6 col-sm-6' : 'col-md-4 col-sm-4'}` }>
        <Link to={'/blog/' + blog.slug}>
        <div className="blog-single-image">
        <img src={blog.banner} alt={blog.title} />
        <div className="overlayBlogImg" />
        <p>{blog.title} <span className="read-more">READ MORE >> </span></p>
        
        </div>
            <h5>READ MORE >></h5>
      </Link>
      </div>
    );
}
