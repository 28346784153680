import { useEffect, useState } from "react";
import "./Live.scss";
import axios from "axios";
import { API_URL } from "../../Utils/constants";
import ContentTopVideo from "../Content/ContentTopVideo/ContentTopVideo";
import background from "../../images/bg-1.jpg";
import Loader from "../Layout/Loader/Loader";

export default function Live() {

    const [loading, setLoading] = useState(true);
    const [notfound, setNotfound] = useState(false);
    const [video, setVideo] =  useState({});

    
  useEffect(() => {
    document.title = 'Live | AAN TV'
    window.scrollTo(0, 0)
    axios
      .get(API_URL + "/getLive")
      .then((res) => {
        if (res.data.code == 200) {
          setVideo(res.data.data);
        } else {
            document.title = 'Page Not Found | AAN TV'
            setNotfound(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setLoading]);

  return loading ? <Loader /> : (
    <>
      {notfound ? (
        <div style={{ backgroundImage: `url(${background})`, backgroundRepeat:'round', backgroundSize:'cover' }}>
          <div className="slider-section-top not-found">
            <h4>404... Page not found.</h4>
          </div>
        </div>
      ) : (
        <>
          {
            video.embed ?  <ContentTopVideo isEpisode={false} isLive={true} topVideo={video} /> : (
                <div style={{ backgroundImage: `url(${background})`, backgroundRepeat:'round', backgroundSize:'cover' }}>
                <div className="slider-section-top not-found">
                  <h4>No Live Stream.</h4>
                </div>
              </div>
            )
          }

        </>
      )}
    </>
  );
}
