import "./ScheduleSliderSingle.scss";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function ScheduleSliderSingle({ index, item }) {

  const [ dayname, setDayname ] = useState('');

  useEffect(() => {
    if(item.day == 1) {
        setDayname('Mon')
    }else if(item.day == 2){
      setDayname('Tues')
    }else if(item.day == 3){
      setDayname('Wed')
    }else if(item.day == 4){
      setDayname('Thur')
    }else if(item.day == 5){
      setDayname('Fri')
    }else if(item.day == 6){
      setDayname('Sat')
    }else if(item.day == 7){
      setDayname('Sun')
    }


  },[])


  return (
    <>
    <div
      className="col-md-3 col-sm-4 col-6 vertical-slide-top schedule-slide-top"
    >
      <Link to={'/drama/' + item.content.slug}>
        <div className="schdule-single-imge">
        <img src={item?.content.poster_vertical} alt="" />
        </div>
      <p><span>{item.time}</span></p>
      <h5>{item.content.title}</h5>
      </Link>
    </div>
    </>
  );
}
