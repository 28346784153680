import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../../Utils/constants";
import BlogSlider from "./Slider/BlogSlider/BlogSlider";
import "./BlogPage.scss";
import Loader from "../Layout/Loader/Loader";
import BlogList from "./BlogList/BlogList";


export default function BlogPage() {
    const [loading, setLoading] = useState(true);
    const [blogs, setBlogs] = useState({});
    const [slider, setSlider] = useState([]);

    
  useEffect(() => {
    document.title = 'Blogs | AAN TV'
    window.scrollTo(0,0);
    axios
      .get(API_URL + "/getBlogPage")
      .then((res) => {
        setBlogs(res.data.blogs);
        setSlider(res.data.sliders)
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setLoading]);

  return (
      loading ? <Loader />  :
      <>
      {
          slider.length > 0 ? <>
          <BlogSlider items={slider} />
          </> : null
      }

      <BlogList blogs={blogs} />

      </>
  );
}