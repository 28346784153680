import { useEffect, useState } from "react";
import "./SingleFeaturedSlider.scss";
import { Link } from "react-router-dom";
function Iframe(props) {
  return (<div className="video-iframe-section" dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
}

export default function SingleFeaturedSlider({ item, isMobile }) {

  const [playVideo, setPlayVideo] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setPlayVideo(true);
  //    }, 5000);
  // },[])
  
  return (
    <>
      {/* <img src={isMobile ? item.mobile_poster : item.poster} /> */}
      {/* <div className="video-iframe-section-outer"> */}
      { 
        isMobile ? <img src={item.mobile_poster} />  : playVideo ? <Iframe iframe={item.embed} /> : <img src={item.poster} /> 
      }
      {/* <Iframe iframe={item.embed} /> */}
       {/* </div>  */}

      {/* <h1>{item.content.title}</h1> */}
      {
        item.content.videos.length > 0 ? <Link to={'/episode/' + item.content.videos[0].slug}>
        <div className="ost-box">
              <p>PLAY OST</p>
        </div>
        </Link> : null
      }

      
      
      
    </>
  );
}
