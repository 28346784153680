import "./SingleMiddleSliderItem.scss";
// import {
//   PlayArrow,
//   Add,
//   ThumbUpAltOutlined,
//   ThumbDownOutlined,
// } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function SingleMiddleSliderItem({ index, item, vertical }) {
  const [isHovered, setIsHovered] = useState(false);
  const [movie, setMovie] = useState({});



  return (
      <div
        className={vertical ? 'listItem vertical-slide' : 'listItem horizontal-slide'}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Link to={'/drama/' + item.slug}>
        <img src={vertical ? item?.poster_vertical : item?.poster_horizontal} alt="" />
        </Link>
      </div>
  );
}