import { useEffect, useRef, useState } from "react";
import "./BlogGrid.scss";
import BlogGridSingle from "./BlogGridSingle/BlogGridSingle";

export default function BlogGrid({ blogs }) {

  return (
      <div className="row blog-section-row">
        { blogs.map((item, i) => (
             <BlogGridSingle key={`blog-single-${i}`} blog={item} index={i} />
            )) }
      </div>
  );
}
