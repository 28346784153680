import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../../Utils/constants";
import background from "../../images/bg-1.jpg";
import backgroundRed from "../../images/bg-4.jpg";

import "./Schedule.scss";
import { Tab } from "bootstrap";
import { Tabs } from "react-bootstrap";
import ScheduleSlider from "./ScheduleSlider/ScheduleSlider";
import Loader from "../Layout/Loader/Loader";

export default function Schedule() {
  const [loading, setLoading] = useState(true);
  const [monContent, setMonContent] = useState([]);
  const [tuesContent, setTuesContent] = useState([]);
  const [wedContent, setWedContent] = useState([]);
  const [thurContent, setThurContent] = useState([]);
  const [friContent, setFriContent] = useState([]);
  const [satContent, setSatContent] = useState([]);
  const [sunContent, setSunContent] = useState([]);
  const [isSmall, setIsSmall] = useState(false);
  

  useEffect(() => {
    window.scrollTo(0,0);
    axios
      .get(API_URL + "/getSchedulePage")
      .then((res) => {
        var mon = res.data.data.filter((item) => item.day == 1);
        var tues = res.data.data.filter((item) => item.day == 2);
        var wed = res.data.data.filter((item) => item.day == 3);
        var thur = res.data.data.filter((item) => item.day == 4);
        var fri = res.data.data.filter((item) => item.day == 5);
        var sat = res.data.data.filter((item) => item.day == 6);
        var sun = res.data.data.filter((item) => item.day == 7);
        setMonContent(mon);
        setTuesContent(tues);
        setWedContent(wed);
        setThurContent(thur);
        setFriContent(fri);
        setSatContent(sat);
        setSunContent(sun);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

      window.addEventListener("resize", changeWidth);
      changeWidth();
  }, [setLoading]);


      const changeWidth = () => {
          if(window.innerWidth > 680){
            setIsSmall(false)
          }else{
            setIsSmall(true)
          }
    };

  

  return loading ? <Loader /> : (
    <div style={{ backgroundImage: `url(${backgroundRed})`, backgroundRepeat:'round', backgroundSize:'cover' }}>

    <div className="schedule-page-section">
      <Tabs defaultActiveKey="monday" id="schedule-tabs">
      <Tab eventKey="monday" title={isSmall ? 'MON' : 'MONDAY'}>
        <div className="schedule-tab-day">
        <ScheduleSlider list={monContent} />
        </div>
      </Tab>
      <Tab eventKey="tuesday" title={isSmall ? 'TUE' : 'TUESDAY'}>
        <div className="schedule-tab-day">
        <ScheduleSlider list={tuesContent} />
        </div>
      </Tab>
      <Tab eventKey="wednesday" title={isSmall ? 'WED' : 'WEDNESDAY'}>
        <div className="schedule-tab-day">
        <ScheduleSlider list={wedContent} />
        </div>
      </Tab>
      <Tab eventKey="thursday" title={isSmall ? 'THU' : 'THURSDAY'}>
        <div className="schedule-tab-day">
        <ScheduleSlider list={thurContent} />
        </div>
      </Tab>
      <Tab eventKey="friday" title={isSmall ? 'FRI' : 'FRIDAY'}>
        <div className="schedule-tab-day">
        <ScheduleSlider list={friContent} />
        </div>
      </Tab>
      <Tab eventKey="saturday" title={isSmall ? 'SAT' : 'SATURDAY'}>
        <div className="schedule-tab-day">
        <ScheduleSlider list={satContent} />
        </div>
      </Tab>
      <Tab eventKey="sunday" title={isSmall ? 'SUN' : 'SUNDAY'}>
        <div className="schedule-tab-day">
        <ScheduleSlider list={sunContent} />
        </div>
      </Tab>
      </Tabs>
    </div>
    </div>
  );
}
