import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import SingleBlogSlider from "./SingleBlogSlider/SingleBlogSlider";

export default function BlogSlider({ items }) {
  return (
    <div className="featured-slider-blog">
      <Carousel autoPlay={true} infiniteLoop={true} interval={5000} showIndicators={false} showStatus={false} showThumbs={false}> 
      {items.map((item, i) => (
        <SingleBlogSlider
          key={`blogslider-${i}`}
          index={i}
          item={item}
        />
      ))}
       </Carousel>
    </div>
  );
}
