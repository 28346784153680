import { useEffect, useState, useRef } from "react";
import contactImage from "../../images/Contact-US.jpg";
import background from "../../images/bg-2.jpg";
import flower from "../../images/flower-final.png";
import divider from "../../images/divider-icon.png";
import "./ContactUs.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import { API_URL } from "../../Utils/constants";


function Iframe(props) {
  return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
}

export default function ContactUs() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [related, setRelated] = useState("");
    const [message, setMessage] = useState("");
    const [ showNameError, setShowNameError] = useState(false);
    const [ showEmailError, setShowEmailError] = useState(false);
    const [ showEmailFormatError, setShowEmailFormatError] = useState(false);
    const [ showPhoneError, setShowPhoneError] = useState(false);
    const [ showRelatedError, setShowRelatedError] = useState(false);
    const [ showMessageError, setShowMessageError] = useState(false);
    const [ showError, setShowError] = useState(false);
    const [ showSubmittError, setShowSubmittError] = useState(false);
    const [ sending, setSending] = useState(false);
    const [ submitted, setsubmitted] = useState(false);

    const myRef = useRef(null)
    const gotoFormTop = () => myRef.current.scrollIntoView()

  useEffect(() => {
    document.title = "Contact Us | AAN TV";
    window.scrollTo(0, 0);
  }, []);

  const nameChange = (event) => {
    setName(event.target.value);
  };

  const emailChange = (event) => {
    setEmail(event.target.value);
  };

  const phoneChange = (event) => {
    setPhone(event.target.value);
  };

  const relatedChange = (event) => {
    setRelated(event.target.value);
  };

  const messageChange = (event) => {
    setMessage(event.target.value);
  };

  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  function getSubmit(){
    setShowNameError(false)
    setShowEmailError(false)
    setShowEmailFormatError(false)
    setShowPhoneError(false)
    setShowRelatedError(false)
    setShowMessageError(false)
    setShowSubmittError(false)
    var errorFound = false;
    if(name.trim() == ''){
        setShowNameError(true)
        errorFound = true;
    }
    if(email.trim() == ''){
        setShowEmailError(true)
        errorFound = true;
    }else if(regex.test(email) == false){
        setShowEmailFormatError(true)
        errorFound = true;
    }
    if(phone.trim() == ''){
        setShowPhoneError(true)
        errorFound = true;
    }
    if(related.trim() == ''){
        setShowRelatedError(true)
        errorFound = true;
    }
    if(message.trim() == ''){
        setShowMessageError(true)
        errorFound = true;
    }

    if(errorFound){
        return;
    }

    setSending(true);

    axios.post(
      API_URL + "/contact",
      { name: name,
        email:email,
        phone: phone,
        related: related,
        message: message
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    ).then(res => {
        if (res.data.code == 200) {
          setsubmitted(true);
          setSending(false)
          gotoFormTop()
        }else{
          setShowSubmittError(true);
          setSending(false);
        }
    }).catch(err => {
      console.log(err)
      setShowSubmittError(true);
      setSending(false);
    })



  }

  return (
    <>
        {/* <div className="banner-top-image">
            <img src={contactImage} />
        </div> */}
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: "round",
          backgroundSize: "cover",
        }}
      >
        <div className="contact-us-page" ref={myRef}>
          <div className="slider-heading-middle">
            <div
              style={{
                backgroundImage: `url(${flower})`,
              }}
              className="flower"
            >
              <h2>CONTACT US</h2>
              <img src={divider} />
            </div>
          </div>

          <div className="row">

          <div className="col-md-6">
          {
            submitted ? (
              <div className="contect-form-submitted">
                  <h5>Form submit successfully.</h5>
              </div>  
                ) : showError ? (
                <div />
              ) : (
                <div className="contact-form-section">
                <h6>Or You Can Fill the Contact Form Below</h6>
    
                <h6>* Name</h6>
                <input type={"text"} value={name} onChange={nameChange} />
                {
                    showNameError ? <p className="error-text">Name is required.</p> : null
                }
                <h6>* Email</h6>
                <input type={"email"} value={email} onChange={emailChange} />
                {
                    showEmailError ? <p className="error-text">Email is required.</p> : null
                }
                {
                    showEmailFormatError ? <p className="error-text">Email is not valid.</p> : null
                }
                <h6>* Phone</h6>
                <input type={"text"} value={phone} onChange={phoneChange} />
                {
                    showPhoneError ? <p className="error-text">Phone is required.</p> : null
                }
                <h6>* Related To</h6>
                <input type={"text"}  value={related} onChange={relatedChange} />
                {
                    showRelatedError ? <p className="error-text">Related To is required.</p> : null
                }
                <h6>* Message</h6>
                <textarea rows={10} onChange={messageChange} value={message}></textarea>
                {
                    showMessageError ? <p className="error-text">Message is required.</p> : null
                }
                { showSubmittError ? (
                   <div className="contect-form-submitted-error">
                     <h5>* Form submission failed. Please try again.</h5>
                  </div> 
                ) : null
                }
                {
                  sending ? (
                    <div className="contact-form-btn">
                    <button >SUBMITING</button>
                  </div>
                  ) : (
                    <div className="contact-form-btn">
                    <button onClick={getSubmit}>SUBMIT</button>
                  </div>
                  )
                }
    


              </div>
              )
            
          }
          </div>

          <div className="col-md-6">
          <div className="address-section">
          <div className="map-embed">
          <Iframe iframe={`<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3319.4472673797445!2d73.048106415136!3d33.697373680703954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbfe96b3cb251%3A0x2947d720a80c98dd!2sMGC%20Complex!5e0!3m2!1sen!2s!4v1662697830500!5m2!1sen!2s" width="270" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`} />
          
          </div>
            <div className="single-address-section">
              <div className="address-icon"><FontAwesomeIcon icon={faLocationDot}  /></div>
              <div className="address-text">
                <h6>Head Office</h6>
                <p>
                  First Floor, MGC Complex,
                  <br /> G-8 Markaz, Islamabad.{" "}
                </p>
              </div>
            </div>

            <div className="single-address-section">
              <div className="address-icon"><FontAwesomeIcon icon={faPhone}  /></div>
              <div className="address-text">
                <h6>Contact</h6>
                <p>+92 (51) 8855111 </p>
              </div>
            </div>

            <div className="single-address-section">
              <div className="address-icon"><FontAwesomeIcon icon={faEnvelope}  /></div>
              <div className="address-text">
                <h6>Email Address</h6>
                <p>info@aan.tv</p>
              </div>
            </div>
          </div>
          </div>
          </div>



        </div>
      </div>
    </>
  );
}
