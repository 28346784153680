import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./ScheduleHome.scss";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

export default class ScheduleHome extends Component {




  render() {

    var total = this.props.items.length;

    var settings = {
      dots: false,
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: total >= 7 ? 7 : total,
      slidesToScroll: 0,
      initialSlide: 0,
      mobileFirst:true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: total >= 5 ? 5 : total,
            slidesToScroll: 1,
            infinite: true,
            autoplay:true,
            autoplaySpeed: 2000,
            arrows: true,
            dots: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: total >= 4 ? 4 : total,
            slidesToScroll: 1,
            infinite: true,
            autoplay:true,
            autoplaySpeed: 2000,
            arrows: true,
            dots: false,
          },
        },
        {
          breakpoint: 787,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            autoplay:true,
            autoplaySpeed: 2000,
            arrows: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            autoplay:true,
            autoplaySpeed: 2000,
            arrows: true,
          },
        },
      ],
    };
    return (
      <div className="schedule-home-section">
        <div className="schedule-single-box double-box">
          <h5>WEEKLY SCHEDULE</h5>
        </div>
        <div className="schedule-home-boxes">
        <Slider {...settings}>
          {this.props.items.map((item, i) => (
            <div
              className="schedule-single-box schedule-single-box-detail"
              key={`schedule-${i}`}
            >
              <div className="schedule-name">
                <Link to={"/drama/" + item.content.slug}>
                  <h6> {item.content.title}</h6>
                </Link>
              </div>
              <div className="schedule-datetime">
                <p>
                  {item.content.day} |{" "}
                  <span className="time-bold">{item.content.time}</span>
                </p>
              </div>
            </div>
          ))}
        </Slider>
        </div>
      </div>
    );
  }
}
