import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../../../../Utils/constants";
import "./BlogSinglePage.scss";
import Loader from "../../../Layout/Loader/Loader";
import background from "../../../../images/bg-1.jpg";
import backgroundRed from "../../../../images/bg-2.jpg";
import img1 from "../../../../images/social-fb.png";
import img2 from "../../../../images/social-twitter.png";
import img3 from "../../../../images/social-whatsapp.png";
import { useParams } from "react-router-dom";
import Helmet from 'react-helmet';


export default function BlogSinglePage() {
  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState({});
  const [notfound, setNotfound] = useState(false);
  const [showAuthor, setShowAuthor] = useState(false);
  const [url, setUrl] = useState('');

  let { slug } = useParams();

  useEffect(() => {
    document.title = "Blogs | AAN TV";
    setUrl(window.location.href)
    window.scrollTo(0, 0);
    axios
      .get(API_URL + "/getBlog/" + slug)
      .then((res) => {
        if (res.data.code == 200) {
          setBlog(res.data.data);
          // document.title = res.data.data.title + " - Blogs | AAN TV";
          // document.images = res.data.data.banner;
          if(res.data.data.author == 'none'){
              setShowAuthor(false)
          }else{
            setShowAuthor(true)
          }
        } else {
          document.title = "Page Not Found | AAN TV";
          setNotfound(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setLoading]);

  return loading ? (
    <Loader />
  ) : (
    <>
      {notfound ? (
        <div
          style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: "round",
            backgroundSize: "cover",
          }}
        >
          <div className="slider-section-top not-found">
            <h4>404... Page not found.</h4>
          </div>
        </div>
      ) : (
        <>
         <Helmet>
         <title>{blog.title} - Blogs | AAN TV</title>
      <meta name="description" content={`${blog.content.substring(0, 100)}`} data-react-helmet="true" />
      <meta property="og:site_name" content={`AAN TV`} data-react-helmet="true" />
      <meta property="og:locale" content="en_US" data-react-helmet="true" />
      <meta property="og:url" content={`${url}`} data-react-helmet="true" />

      <meta
        property="og:type"
        content={'article'}
        data-react-helmet="true"
      />
      <meta
        property="og:title"
        content={`${blog.title} - Blogs | AAN TV`}
        data-react-helmet="true"
      />
      <meta
        property="og:description"
        content={`${blog.content.substring(0, 100)}`}
        data-react-helmet="true"
      />
      <meta
        property="og:image"
        content={`${blog.banner}`}
        data-react-helmet="true"
      />

         </Helmet>
        <div className="blog-main-section">
          <div className="blog-main-banner">
            <img src={blog.poster} alt={blog.title} />
          </div>
          <h1 className="blog-title">{blog.title}</h1>

          <div
            style={{
              backgroundImage: `url(${backgroundRed})`,
              backgroundRepeat: "round",
              backgroundSize: "cover",
            }}
          >
            <div className="contact-us-page" >
              <div className="blog-top-section">
                {
                  showAuthor ?  <h5>{blog.author}</h5> : null
                }
                
                {/* <a href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`} target="_blank"><img src={img1} /></a>
                <a href={`https://twitter.com/share?text=${encodeURIComponent(blog.title)}&url=${encodeURIComponent(url)}`} target="_blank"><img src={img2} /></a>
                <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(blog.title + ' '+ url)}`} target="_blank"><img src={img3} /></a> */}
              </div>

              <div className="blog-content" dangerouslySetInnerHTML={{__html: blog.content}}></div>
              
            </div>
          </div>
        </div>
        </>
      )}
    </>
  );
}
