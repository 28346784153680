import "./VerticalLargeItem.scss";
// import {
//   PlayArrow,
//   Add,
//   ThumbUpAltOutlined,
//   ThumbDownOutlined,
// } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function VerticalLargeItem({ index, item }) {
  const [isHovered, setIsHovered] = useState(false);
  const [movie, setMovie] = useState({});

  return (
    <div
      className="listItem vertical-slide-top"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link to={'/drama/' + item.slug}>
        <img src={item?.poster_vertical} alt="" />
      </Link>
    </div>
  );
}
