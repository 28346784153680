import { useEffect, useState } from "react";
import "./Episodes.scss";
import axios from "axios";
import { API_URL } from "../../../Utils/constants";
import { useParams } from "react-router-dom";
import ContentTopVideo from "../ContentTopVideo/ContentTopVideo";
import VideoList from "../VideoList/VideoList";
import background from "../../../images/bg-1.jpg";
import Loader from "../../Layout/Loader/Loader";

export default function Episodes() {
    
    let { slug } = useParams();

    const [loading, setLoading] = useState(true);
    const [notfound, setNotfound] = useState(false);
    const [video, setVideo] =  useState({});
    const [episodes, setEpisodes] = useState([]);
    const [first, setFirst] = useState(true);
    const [osts, setOsts] = useState([]);
    const [bts, setBts] = useState([]);
    const [fetching, setfetching] = useState(true);
    
  useEffect(() => {
    window.scrollTo(0, 0)
    setfetching(true)
    axios
      .get(API_URL + "/getEpisode/" + slug)
      .then((res) => {
        if (res.data.code == 200) {
            document.title = res.data.data.title + ' - ' + res.data.data.content.title + ' | AAN TV'
          setVideo(res.data.data);
          var tempepisodes = [];
          var temposts = [];
          var tempbts = [];
          res.data.data.content.videos.map(item => {
            if(item.type == 1){
              tempepisodes.push(item);
            }
            if(item.type == 4){
              temposts.push(item);
            }
            if(item.type == 6){
              tempbts.push(item);
            }
          })
          setEpisodes(tempepisodes)
          setOsts(temposts)
          setBts(tempbts)
          if(!first){
              window.scrollTo(0, 0)
          }
          setFirst(false)
          setfetching(false)
        } else {
            document.title = 'Page Not Found | AAN TV'
            setNotfound(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setLoading, slug]);

  return loading ? <Loader /> : (
    <>
      {notfound ? (
        <div style={{ backgroundImage: `url(${background})`, backgroundRepeat:'round', backgroundSize:'cover' }}>
          <div className="slider-section-top not-found">
            <h4>404... Page not found.</h4>
          </div>
        </div>
      ) : (
        <>
          
           <ContentTopVideo isEpisode={true} isLive={false} topVideo={video} />
          
          {
            episodes.length > 0 ? !fetching && (<VideoList gotoEpisode={null} videos={episodes} title={'Episodes'} bts={bts} osts={osts}  /> ) : null
          }
          {/* {
            episodes.length > 0 ? !fetching && (<VideoList gotoEpisode={null} videos={episodes} title={'Episodes'} bts={bts} osts={osts}  /> ) : <div style={{ backgroundImage: `url(${background})`, backgroundRepeat:'round', backgroundSize:'cover' }}>
            <div className="slider-section-top not-found">
              <h4>No videos available right now.</h4>
            </div>
          </div>
          } */}



        </>
      )}
    </>
  );
}
