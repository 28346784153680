import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { API_URL } from "../../Utils/constants";
import "./ContentScreen.scss";

import { useParams } from "react-router-dom";
import background from "../../images/bg-1.jpg";
import ContentBanner from "./ContentBanner/ContentBanner";
import ContentSchedule from "./ContentSchedule/ContentSchedule";
import ContentTopVideo from "./ContentTopVideo/ContentTopVideo";
import VideoList from "./VideoList/VideoList";
import SynopsisSection from "./SynopsisSection/SynopsisSection";
import Loader from "../Layout/Loader/Loader";
import backgroundred from "../../images/bg-2.jpg";
import flower from "../../images/flower-final.png";
import divider from "../../images/divider-icon.png";


function Iframe(props) {
  return (<div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }} />);
}

export default function ContentScreen() {
  let { slug } = useParams();

  const myRef = useRef(null)
  const fixBarRef = useRef(null)

  const gotoEpisode = () => {
    window.scrollTo(0, myRef.current.offsetTop - 70)
  }


  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState({});
  const [topVideo, setTopVideo] = useState(null);
  const [notfound, setNetfound] = useState(false);
  const [ostFound, setOstFound] = useState('');
  const [episodes, setEpisodes] = useState([]);
  const [osts, setOsts] = useState([]);
  const [bts, setBts] = useState([]);
  const [showep, setShowep] = useState(true);
  const [showost, setShowost] = useState(false);
  const [showbts, setShowbts] = useState(false);
  const [positionFixed, setPositionFixed] = useState(false);
  const [scrollvalue, setScrollvalue] = useState(0);
  const [showVideoSection, setShowVideoSection] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    axios
      .get(API_URL + "/getContent/" + slug)
      .then((res) => {
        if (res.data.code == 200) {
          setContent(res.data.data);
          document.title = res.data.data.title + ' | AAN TV'
          var topvideo = null;
          var firstVideo = false;
          setShowep(false)
          setShowost(false)
          setShowbts(false)
          if (res.data.data.videos.length > 0) {
            var tempepisodes = [];
            var temposts = [];
            var tempbts = [];
            var foundeps = false;
            res.data.data.videos.map(item => {

              if (item.type == 1) {
                foundeps = true;
                tempepisodes.push(item);
              }
              if (item.type == 4) {
                foundeps = true;
                temposts.push(item);
              }
              if (item.type == 6) {
                foundeps = true;
                tempbts.push(item);
              }
              if(!firstVideo){
                if (item.type == 3) {
                  topvideo = item;
                  firstVideo = true;
               }else if (item.type == 5) {
                 topvideo = item;
               }
              }


            })

            setEpisodes(tempepisodes)
            setBts(tempbts)
            setOsts(temposts)

            temposts.map(item => {
              if (item.type == 4) {
                if (!topvideo) {
                  topvideo = item;
                }
                setOstFound(item.slug)
              }
            })

            if (!topvideo) {
              topvideo = res.data.data.videos[0]
            }
            setTopVideo(topvideo);

          } else {
            setEpisodes([])
            setBts([])
            setOsts([])
          }
          
          setShowVideoSection(foundeps)
          if(tempepisodes.length > 0){
            setShowep(true)
          }else if(temposts.length > 0){
            setShowost(true)
          }else if(tempbts.length > 0){
            setShowbts(true)
          }
          window.addEventListener("scroll", changeScroll);
          setTimeout(() => {
            changeScroll()
          }, 500);
        } else {
          document.title = 'Page Not Found | AAN TV'
          setNetfound(true);
        }
        setLoading(false);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setLoading, slug]);


  useEffect(() => {
    if (showVideoSection) {
      if (!loading) {
        if (scrollvalue >= (fixBarRef.current.offsetTop - 74)) {
          setPositionFixed(true)
        } else {
          setPositionFixed(false)
        }
      }
    }
  }, [scrollvalue])


  const changeScroll = () => {
    setScrollvalue(window.scrollY)
  }


  function showepsection() {
    setShowep(true)
    setShowost(false)
    setShowbts(false)
    gotoEpisode()
  }

  function showostsection() {
    setShowep(false)
    setShowost(true)
    setShowbts(false)
    gotoEpisode()
  }

  function showbtssection() {
    setShowep(false)
    setShowost(false)
    setShowbts(true)
    gotoEpisode()
  }


  return loading ? <Loader /> : (
    <>
      {notfound ? (
        <div style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'round', backgroundSize: 'cover' }}>
          <div className="slider-section-top not-found">
            <h4>404... Page not found.</h4>
          </div>
        </div>
      ) : (
        <>
          <ContentBanner
            image={content.banner}
            urdu={content.synopsis_urdu}
            synopsis={content.synopsis}
            directors={content.directors}
            writers={content.writers}
            producers={content.producers}
            actors={content.actors}
            hosts={content.hosts}
            ost={ostFound}
          />
          {/* <ContentSchedule 
            day_full={content.day_full}
            time_full={content.time_full}
            day_repeat={content.day_repeat}
            time_repeat={content.time_repeat}
          /> */}

          {/* <div onClick={gotoEpisode} className="episodes">
            <h3>goto episodes</h3>
          </div> */}
          {
            showVideoSection ? (
              <div ref={fixBarRef} >
                <div className={`content-top-division`}>
                  <h5>WATCH</h5>
                  {
                    episodes.length > 0 ? (
                      <div onClick={showepsection} className="content-top-btns ep-btn">
                        EPISODES
                      </div>
                    ) : null
                  }
                  {
                    osts.length > 0 ? (
                      <div onClick={showostsection} className="content-top-btns ost-btn">
                      OST
                    </div>
                    ) : null
                  }
                  {
                    bts.length > 0 ? (
                      <div onClick={showbtssection} className="content-top-btns bts-btn">
                        BTS
                      </div>
                    ) : null
                  }



                </div>
                <div className={`content-top-division ${positionFixed ? 'fixEpisodesSection' : 'hidesection'}`}>
                  <h5>WATCH</h5>
                  {
                    episodes.length > 0 ? (
                      <div onClick={showepsection} className="content-top-btns ep-btn">
                        EPISODES
                      </div>
                    ) : null
                  }
                  {
                    osts.length > 0 ? (
                      <div onClick={showostsection} className="content-top-btns ost-btn">
                      OST
                    </div>
                    ) : null
                  }
                  {
                    bts.length > 0 ? (
                      <div onClick={showbtssection} className="content-top-btns bts-btn">
                        BTS
                      </div>
                    ) : null
                  }
                </div>
              </div>
            ) : null
          }


          <SynopsisSection

            urdu={content.synopsis_urdu}
            synopsis={content.synopsis}
            directors={content.directors}
            writers={content.writers}
            producers={content.producers}
            actors={content.actors}
            hosts={content.hosts}
            contentID={content.id}
          />


          {
            topVideo ? <ContentTopVideo islive={false} isEpisode={false} topVideo={topVideo} /> : null
          }

          {
            //   episodes.length > 0 ? <div ref={myRef}><VideoList osts={osts} bts={bts}  videos={episodes}  /></div> : <div style={{ backgroundImage: `url(${background})`, backgroundRepeat:'round', backgroundSize:'cover' }}>
            //   <div className="slider-section-top not-found">
            //     <h4>No videos available right now.</h4>
            //   </div>
            // </div>
          }

          {
            showVideoSection ? (
              <div ref={myRef}>
                {
                  showep && episodes.length > 0 ? <VideoList title="ALL EPISODES" gotoEpisode={gotoEpisode} videos={episodes} /> : null
                }
                {
                  showost && osts.length > 0 ? <div style={{ backgroundImage: `url(${backgroundred})`, backgroundRepeat: 'round', backgroundSize: 'cover' }}>
                    <div className="slider-section-top-drama">
                      <div className="slider-heading-middle">
                        <div
                          style={{
                            backgroundImage: `url(${flower})`
                          }}
                          className="flower"
                        >
                          <h2> OST </h2>
                          <img src={divider} />
                        </div>
                      </div>
                      <div className="ost-section-content">
                        <div className="video-player-section">
                          <Iframe iframe={osts[0].detail} />
                        </div>
                      </div>
                    </div>
                  </div> : null
                }
                {
                  showbts && bts.length > 0 ? <VideoList title="BTS" gotoEpisode={gotoEpisode} videos={bts} /> : null
                }

              </div>
            ) : null
          }



        </>
      )}
    </>
  );
}
