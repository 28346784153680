
import ScheduleSliderSingle from "../ScheduleSliderSingle/ScheduleSliderSingle";

export default function ScheduleSlider({list}) {


  return list.length > 0 ? <div className="row">{list.map((item, i) => (
    <ScheduleSliderSingle key={`listItem-${i}`} index={i} item={item} />
  ))}</div> : null
  

}