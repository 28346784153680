import "./ContentBanner.scss";
import { Link } from "react-router-dom";

export default function ContentBanner({
  image,
  urdu,
  synopsis,
  directors,
  writers,
  producers,
  actors,
  hosts,
  ost,
}) {
  return (
    <div className="banner-section">
      <img src={image} />
         {/* <div className="banner-section-inner"> */}
         {ost ? (
          <Link to={"/episode/" + ost}>
            <div className="ost-box">
              <p>PLAY OST</p>
            </div>
          </Link>
        ) : null}
         {/* </div> */}

    </div>
  );
}
