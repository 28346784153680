import { useEffect } from "react";
import background from "../../images/bg-1.jpg";
import "./ComingSoon.scss";
import { useLocation } from 'react-router-dom';

export default function ComingSoon({title}) {
 
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  },[location])

  return (
    <div style={{ backgroundImage: `url(${background})`, backgroundRepeat:'round', backgroundSize:'cover' }}>
      <div className="slider-section-top-soon not-found">
        {
          title ? (
            <div className="coming-soon-section">
            <h4>{title}</h4>
            <p>Coming Soon.</p>
            </div>
          ) :  <h4>Coming Soon.</h4>
        }
       
      </div>
    </div>
  );
}
