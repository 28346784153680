import "./ProgramSingleSlide.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function ProgramSingleSlide({ video }) {
  return (
    <div className="col-md-6 col-sm-6 program-section-column">
      <Link to={"/drama/" + video.slug}>
        <div className="program-image-single">
          <div className="program-image-wraper">
            <img src={video.poster_horizontal} alt="" />
          </div>
        </div>
      </Link>
    </div>
  );
}
