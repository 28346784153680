import { useEffect, useRef, useState } from "react";
import "./ProgramSlider.scss";
import divider from "../../../../images/divider-icon.png";
import ProgramSingleSlide from "./ProgramSingleSlide/ProgramSingleSlide";

export default function ProgramSlider({ items, gotoPrograms }) {

  const [showmore, setShowMore ] = useState(true)

  useEffect(() => {
    if(items.length > 4){
      setShowMore(true)
    }else{
      setShowMore(false)
    }
  },[])

  function showAll() {
    setShowMore(false)
  }


  return (
    <>
      <div className="row program-section-row">
        { items.map((item, i) => (
             !showmore || i < 4 ? <ProgramSingleSlide key={`video-` + i} video={item} /> : null
            )) }
      </div>

        {
          showmore ? <p className="more-btn" onClick={showAll}>More</p> : null
        }
      

    </>
  );
}
