import { useEffect, useRef, useState } from "react";
import "./BlogList.scss";
import background from "../../../images/bg-4.jpg";
import flower from "../../../images/flower-final.png";
import divider from "../../../images/divider-icon.png";
import BlogGrid from "./BlogGrid/BlogGrid";


export default function BlogList({ blogs }) {


  return (
    <div style={{ backgroundImage: `url(${background})`, backgroundRepeat:'round', backgroundSize:'cover' }}>
      <div className="slider-section-top-drama" >
        <div className="slider-heading-middle">
          <div
            style={{
              backgroundImage: `url(${flower})`,

            }}
            className="flower"
          >
            <h2> BLOGS </h2>
            <img src={divider} />
          </div>
        </div>

        <BlogGrid blogs={blogs} />
        

      </div>
    </div>
  );
}
