import { useEffect, useState } from "react";
import background from "../../../images/footer-bg.jpg";
import backgroundMobile from "../../../images/bg-footer-mobile.jpg";
import sbmedia from "../../../images/sb-media-logo.png";
import icon1 from "../../../images/instagram-icon.png";
import icon2 from "../../../images/facebook-icon.png";
import icon3 from "../../../images/twitter-icon.png";
import icon4 from "../../../images/whatsapp-icon.png";
import "./Footer.scss";
import divider from "../../../images/footer-divier.png";
import { Link } from "react-router-dom";

export default function Footer() {
    const [ isMobile, setIsmobile ] = useState(false);

    useEffect(() => {
        if(window.innerWidth > 480){
            setIsmobile(false)
          }else{
            setIsmobile(true)
          }
    },[])

  return (
    <>
    <div style={{ backgroundImage: `url(${isMobile ? backgroundMobile : background})`, backgroundRepeat:'round', backgroundSize:'cover' }}>
    <div className="footer-section">
        <div className="footer-top-section">

            <div className="footer-title">
                    {/* <h1>M NETWORK</h1> */}
                <img src={sbmedia} className="sbmedia-logo" />
            </div>
            <div className="footer-section-1">
            <div className="footer-divider">
                <img src={divider} />
            </div>
            <div className="footer-menu-1">
                {/* <p>Aan TV</p>
                <p>Planet 6</p>
                <p>M News</p>
                <p>M Life</p>
                <p>M Sports</p>
                <p>ME</p> */}
            <Link to={'/about-us'}> <p>About Us</p></Link>
            <Link to={'/brand-guidelines'}><p>Brand Guidelines</p></Link>
            <Link to={'/contact-us'}><p>Contact Us</p></Link>
            </div>
            <div className="footer-section-2">
            <div className="footer-menu-1">
            {/* <Link to={'/about-us'}> <p>About Us</p></Link>
            <Link to={'/brand-guidelines'}><p>Brand Guidelines</p></Link>
            <Link to={'/contact-us'}><p>Contact Us</p></Link> */}
            {/* <p className="empty-menu-space" ></p> */}
            <Link to={'/terms-and-conditions'}><p>Terms & Conditions</p></Link>
            <Link to={'/privacy-policy'}><p>Privacy Policy</p></Link>
            </div>
            </div>
            {/* <div className="footer-menu-1">
            </div> */}
            </div>

        </div>

        <div className="footer-end">
            <div className="row">
                <div className="col-md-8 col-sm-8 foot-copyright">
                <p>
                    Copyright © 2022 SB Media(Pvt) Ltd - All rights reserved.
                    </p>
                </div>

            </div>
             
        </div>
    </div>
  </div>
  <div className="footer-copyright-mobile">
    <h6>Copyright © 2022 SB Media(Pvt) Ltd - All rights reserved.</h6>
  </div>
  </>
  );
}