import { useEffect, useRef, useState } from "react";
import "./ProgramList.scss";
import background from "../../../../images/bg-4.jpg";
import flower from "../../../../images/flower-final.png";
import divider from "../../../../images/divider-icon.png";
import ProgramSlider from "../ProgramSlider/ProgramSlider";


export default function ProgramList({ programs }) {

    const myRef = useRef(null)
    const gotoPrograms = () => myRef.current.scrollIntoView()

  return (
    <div style={{ backgroundImage: `url(${background})`, backgroundRepeat:'round', backgroundSize:'cover' }}>
      <div className="slider-section-top-drama"  ref={myRef}>
        <div className="slider-heading-middle">
          <div
            style={{
              backgroundImage: `url(${flower})`,

            }}
            className="flower"
          >
            <h2> PROGRAMS </h2>
            <img src={divider} />
          </div>
        </div>


        <ProgramSlider items={programs} gotoPrograms={gotoPrograms} />

        

      </div>
    </div>
  );
}
