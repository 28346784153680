import { useEffect, useState } from "react";
import SingleVideo from "../SingleVideo/SingleVideo";
import "./VideoCustomSlider.scss";
import leftBtn from "../../../../images/ep-left.png";
import leftLockBtn from "../../../../images/ep-left-lock.png";
import rightBtn from "../../../../images/ep-right.png";
import rightLockBtn from "../../../../images/ep-right-lock.png";

export default function VideoCustomSlider({ items, gotoEpisode }) {



  return (
      <div className="row video-section-row">
        {items.map((item, i) => (
              <SingleVideo key={`video-` + i} video={item} />
            ))}
      </div>
  );
}
