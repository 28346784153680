import "./SingleVideo.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import playbtn from "../../../../images/play-btn.png";

export default function SingleVideo({  video }) {

  return (
        <div className="col-md-3 col-sm-4 col-6 video-section-column">
        <Link to={'/episode/' + video.slug}>
      <div className="video-image-single">
      <div className={'video-image-wraper'}>
        <img src={video.poster} alt="" />
        <div className="video-image-title">
              <h6>{video.title}</h6>
        </div>
        <div className="video-overlay-image">
            <img src={playbtn} alt="" />
        </div>
      </div>
      </div>
        </Link>
      </div>
  );
}