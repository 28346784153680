import { useEffect } from "react";
import background from "../../../images/text-bg.jpg";
import "./AboutUsPage.scss";


export default function AboutUsPage() {

  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  return (
    <div style={{ backgroundImage: `url(${background})`, backgroundRepeat:'round', backgroundSize:'cover' }}>
    <div className="text-page-section">
        <h4>OUR VISION</h4>
        <p>SB Media (Pvt.) Ltd. intends to provide its audience with the authentic and meaningful information and entertainment in Pakistan. Through our FM Radio Stations, Newspaper and upcoming Satellite TV Channels (Entertainment and Infotainment Categories), we will reintroduce the core values of freedom of speech, people’s right to information and healthy entertainment. We intend to accomplish the satisfaction of our viewers for the ever-growing appetite for entertainment and information through an unbiased, intriguing and entertaining original programming.</p>
        <h4>OUR MISSION</h4>
        <p>To deliver authentic and premium content to our multi-generational, multi-cultural and multi- platform audience across the globe. Our focus will be on growth while providing prodigious value to our customers, viewers and stakeholders.</p>
        <h4>COMPANY PROFILE</h4>
        <p>SB Media (Pvt.) Ltd consists of multiple media outlets consisting of Newspaper, FM Radio Stations and upcoming Satellite TV Channels. We inform and entertain our audience with authentic information and innovative content through various mediums. We have deployed state-of-the-art technology and equipment to provide the best quality content to our audience through our TV Channels. Our content, production and reporting will set new standards of excellence for others to follow thus changing the existing trends in the media industry.</p>
    </div>
  </div>
  );
}