import { useEffect, useRef, useState } from "react";
import "./ModalSearch.scss";
import logo from "../../../../images/aan-logo-130-hd.png";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { API_URL } from "../../../../Utils/constants";

export default function ModalSearch({ onClick }) {
  const [search, setSearch] = useState("");
  const [searching, setSearching] = useState(false);
  const [contents, setContents] = useState([]);
  const [show, setShow] = useState(false);

  const controllerTime = useRef(null);

  const handleChange = (event) => {
    setSearch(event.target.value);
    clearTimeout(controllerTime.current);
    controllerTime.current = setTimeout(() => {
        if(event.target.value.trim() == ''){
            setContents([])
        }
        fetchContent(event.target.value);
    }, 700);
  };

  useEffect(() => {
    return () => {
      clearInterval(controllerTime.current);
    };
  }, []);

  function fetchContent(searchvalue) {

    if (searchvalue.trim()) {
  
        setSearching(true);
        setShow(false);
        let searchWords = searchvalue.split(" ");


        axios.post(
          API_URL + "/search",
          { search: searchWords },
          {
            headers: { "Content-Type": "application/json" },
          }
        ).then(res => {
            if (res.data.code == 200) {
              setContents(res.data.data);
              setSearching(false);
              setShow(true);
            }
        })

      } 
  }

  return (
    <div className="search-modal">
      <div className="search-container">
        <div className="container">
          <div className="search-header row">
            <div className="col-2 col-md-1">
              <NavLink to="/">
                <img onClick={onClick} src={logo} alt="Logo" />
              </NavLink>
            </div>
            <div className="col-10 col-md-11">
              <form>
                <div className="search-input">
                  <span className="search-icon">
                    <FontAwesomeIcon icon={faSearch} />
                  </span>
                  <input
                    type="text"
                    value={search}
                    placeholder="Search"
                    className="form-control typeahead"
                    onChange={handleChange}
                  />
                  <button className="close-icon" onClick={onClick}>
                    x
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="result-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-1"></div>
            <div className="col-12 col-md-11 search-result-section">
              {contents.length > 0 ? contents.map((item, i) => (
                <div className="result-single" key={`result-` + i}>
                    <NavLink to={'/drama/' + item.slug}>
                  <h3 onClick={onClick}>{item.title}</h3>
                  </NavLink>
                </div>
              )) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
