import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./components/Layout/Header/Header";
import Homepage from "./components/Homepage/Homepage";

import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ProgramHome from "./components/Program/ProgramHome/ProgramHome";
import Schedule from "./components/Schedule/Schedule";
import ContentScreen from "./components/Content/ContentScreen";
import Footer from "./components/Layout/Footer/Footer";
import Episodes from "./components/Content/Episodes/Episodes";
import Live from "./components/Live/Live";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import BlogPage from "./components/BlogPage/BlogPage";
import AboutUsPage from "./components/Pages/AboutUsPage/AboutUsPage";
import { useEffect, useState } from "react";
import ContactUs from "./components/ContactUs/ContactUs";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./components/Pages/TermsAndConditions/TermsAndConditions";
import BlogSinglePage from "./components/BlogPage/BlogList/BlogSinglePage/BlogSinglePage";
import ReactGA from 'react-ga';
import Career from "./components/Career/Career";
import ReactFbq from 'react-fbq';

ReactGA.initialize('UA-240514480-1');
ReactFbq.initialize({ id: '1189540568266785' });

function App() {

  const [locked, setLocked] = useState(false);
  const [password, setPassword] = useState("");
  const [wrong, setWrong] = useState(false);


  function logged(){

    var items = {
      islogged : true
    }
    localStorage.setItem('aan-tv-logged-local', JSON.stringify(items));
    setLocked(false)
  }

  const handleChange = (event) => {
    setPassword(event.target.value);
  }; 

  function checkpassword(){
    setWrong(false)
    if(password == '051051'){
      console.log(true)
      logged()
    }else{
      setWrong(true)
      console.log(false)
    }
  }

  return (
    <div className="bg-home">
      {
        locked ? (
          <div className="login-section">
              <div>
            <div className="loginitems">
            <input
                    type="text"
                    value={password}
                    placeholder="Password"
                    className="form-control"
                    onChange={handleChange}
                  />
                  <button className="btn btn-primary" onClick={checkpassword}>
                    Submit
                  </button>
                  </div>
                  {
                    wrong ? <p>Password is incorrect</p> : null
                  }
            </div>
          </div>
        ) : (
          <BrowserRouter>
          <Header />
          <div className="padding-menu">
          <Routes>
            <Route exact path="/" element={<ProgramHome />}></Route>
            <Route path="program" element={<ProgramHome />}></Route>
            <Route path="blogs" element={<BlogPage />}></Route>
            <Route path="live" element={<Live />}></Route>
            <Route path="schedule" element={<Schedule />}></Route>
            <Route path="about-us" element={<AboutUsPage />}></Route>
            <Route path="contact-us" element={<ContactUs />}></Route>
            <Route path="career" element={<Career />}></Route>
            <Route path="history" element={<ComingSoon title={'History'} />}></Route>
            <Route path="our-team" element={<ComingSoon title={'Our Team'} />}></Route>
            <Route path="brand-guidelines" element={<ComingSoon title={'Brand Guidelines'} />}></Route>
            <Route path="terms-and-conditions" element={<TermsAndConditions />}></Route>
            <Route path="privacy-policy" element={<PrivacyPolicy />}></Route>
            {/* <Route path="get-in-touch" element={<ComingSoon title={'Get in touch'} />}></Route> */}
            <Route path="privacy-guideline" element={<ComingSoon title={'Privacy Guideline'} />}></Route>
            <Route path="blog/:slug" element={<BlogSinglePage />}></Route>
            <Route path="drama/:slug" element={<ContentScreen />}></Route>
            <Route path="episode/:slug" element={<Episodes />}></Route>
          </Routes>
          </div>
          <Footer />
        </BrowserRouter>
        )
      }

    </div>
  );
}

export default App;
