import { NavLink, useLocation } from "react-router-dom";
import "./Header.scss";
import logo from "../../../images/aan-logo-130-hd.png";
import menuImage from "../../../images/menu.png";
import Search from "../Search/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import icon1 from "../../../images/fb-round.png";
import icon2 from "../../../images/yt-round.png";
import icon3 from "../../../images/twitter-round.png";
import icon4 from "../../../images/insta-round.png";
import icon5 from "../../../images/tiktok-round.png";
import ReactGA from 'react-ga';
import ReactFbq from 'react-fbq';

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {

  }, []);

  const toggleNav = () => {
    setShowMenu(!showMenu);
  };
  const closeNav = () => {
    setShowMenu(false);
  };

  let location = useLocation();


  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    ReactFbq.pageView();
  },[location])

  return (
    <>
    <div className="headerSection-Top">
      <div className="HeaderSection">
        <div className="menuLogoList">
          <div className="MenuLogo">
            <NavLink to="/">
              <img src={logo} alt="Logo" onClick={closeNav} />
            </NavLink>
          </div>
          <ul className="MenuList">
            {/* <li className="MenuItem">
            <NavLink to="/" className={(navData) => (navData.isActive ? 'active' : '')}>HOME</NavLink>
          </li> */}
            <li className="MenuItem">
              <NavLink
                to="program"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                PROGRAM
              </NavLink>
            </li>

            <li className="MenuItem">
              <NavLink
                to="schedule"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                SCHEDULE
              </NavLink>
            </li>
            {/* <li className="MenuItem">
              <NavLink
                to="live"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                AAN LIVE
              </NavLink>
            </li> */}
            <li className="MenuItem">
              <NavLink
                to="blogs"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                LAUNCH CEREMONY
              </NavLink>
            </li>
            <li className="MenuItem">
              <NavLink
                to="about-us"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                ABOUT US
              </NavLink>
            </li>
            <li className="MenuItem">
              <NavLink
                to="contact-us"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                CONTACT US
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="search-section">
          <Search />
        </div>

        <div className="mobile-menu" onClick={toggleNav}>
          {/* <div> */}
          <img src={menuImage} />
            {/* <FontAwesomeIcon icon={faBars} /> */}
          {/* </div> */}
        </div>
      </div>

      <ReactModal 
           isOpen={showMenu}
           contentLabel="onRequestClose"
           onRequestClose={closeNav}
           className="ModalMenu"
           ariaHideApp={false}
           overlayClassName="OverlayMenu"
        >
          <h1 className="closeMenuModal" onClick={closeNav}>X</h1>
        <ul className="MobileMenuList">
                  {/* <li className="MenuItem">
                  <NavLink to="/" className={(navData) => (navData.isActive ? 'active' : '')}>HOME</NavLink>
                </li> */}
                  <li onClick={toggleNav} className="MobileMenuItem">
                    <NavLink
                      to="program"
                      className={(navData) => (navData.isActive ? "active" : "")}
                    >
                     <p>PROGRAM</p> 
                    </NavLink>
                  </li>

                  <li onClick={toggleNav} className="MobileMenuItem">
                    <NavLink
                      to="schedule"
                      className={(navData) => (navData.isActive ? "active" : "")}
                    >
                       <p>SCHEDULE</p> 
                      
                    </NavLink>
                  </li>
                  {/* <li onClick={toggleNav} className="MobileMenuItem">
                    <NavLink
                      to="live"
                      className={(navData) => (navData.isActive ? "active" : "")}
                    >
                       <p>AAN LIVE</p> 
                      
                    </NavLink>
                  </li> */}
                  <li onClick={toggleNav} className="MobileMenuItem">
                    <NavLink
                      to="blogs"
                      className={(navData) => (navData.isActive ? "active" : "")}
                    >
                       <p>LAUNCH CEREMONY</p> 
                      
                    </NavLink>
                  </li>
                  <li onClick={toggleNav} className="MobileMenuItem">
                    <NavLink
                      to="about-us"
                      className={(navData) => (navData.isActive ? "active" : "")}
                    >
                       <p>ABOUT US</p> 
                      
                    </NavLink>
                  </li>
                  <li onClick={toggleNav} className="MobileMenuItem">
                    <NavLink
                      to="contact-us"
                      className={(navData) => (navData.isActive ? "active" : "")}
                    >
                       <p>CONTACT US</p> 
                      
                    </NavLink>
                  </li>
                </ul>
        </ReactModal>

    </div>
    <div className="social-fixed">
      <a target="_blank" href="https://www.facebook.com/aantvpk"><img src={icon1} /></a>
      <a target="_blank" href="https://www.youtube.com/channel/UCTvcn2M4zQejIvjCNnzucqA"><img src={icon2} /></a>
      <a target="_blank" href="https://twitter.com/aantvpk"><img src={icon3} /></a>
      <a target="_blank" href="https://www.instagram.com/aantvpk"><img src={icon4} /></a>
      <a target="_blank" href="https://www.tiktok.com/@aantvpk"><img src={icon5} /></a>
    </div>
    </>
  );
}
