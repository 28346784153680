import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import "./Search.scss";
import ModalSearch from "./ModalSearch/ModalSearch";
import SearchModal from "./SearchModal/SearchModal";

export default class Search extends React.Component {
  constructor(props) {
    super(props);
    // this.state = { value: "" };

    // this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  // handleChange(event) {
  //   this.setState({ value: event.target.value });
  // }

  // handleSubmit(event) {
  //   alert("A name was submitted: " + this.state.value);
  //   event.preventDefault();
  // }

  render() {
    return (
      <SearchModal />
      // <ModalSearch />
      // <form onSubmit={this.handleSubmit} className="search-box">
      //   <input
      //     type="text"
      //     value={this.state.value}
      //     placeholder="Search"
      //     className="search-input"
      //     onChange={this.handleChange}
      //   />

      //   <button type="submit" className="search-icon">
      //   <FontAwesomeIcon icon={faSearch}  />
      //   </button>
      // </form>
    );
  }
}
