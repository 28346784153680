import { useEffect } from "react";
import background from "../../../images/text-bg.jpg";


export default function PrivacyPolicy() {


    useEffect(() => {
        window.scrollTo(0,0)
      },[])


  return (
    <div style={{ backgroundImage: `url(${background})`, backgroundRepeat:'round', backgroundSize:'cover' }}>
    <div className="text-page-section">
        <h2>Privacy Policy – AAN TV</h2>
        <p>This privacy policy describes the types of information AAN TV may collect from you, the user, or that you may provide when you visit the site and it describes our practices for collecting, using, maintaining, protecting, and disclosing that information. The policy applies to information AAN TV collect on the site, from you, whether received in person, by email, or other electronic messages between you and any of the websites, through any download you may make from the site or by uploading information to the site, through your interaction with the third-party websites or services linked to site, or from others such as vendors and service providers that assist us with our business operations. Please read this policy carefully to understand our policies and practices regarding your information and how AAN TV treat it. If you do not agree with our policies and practices, you may elect not to use the site. However, by accessing or using the site, you agree to the terms and condition of the policy, which may change from time to time with or without notice. Your continued use of the site following any changes is deemed to be your acceptance of those changes, so please check this Policy periodically for updates. </p>
<p>The Privacy Policy mentioned below constitute legally binding agreement between You and AAN TV for your usage of the Website (hereinafter referred to as the ‘Site’) and the services that allow the distribution and reception of television and radio shows, text, audio, videos, images, games, software, technical stuff such meta data and open-source code, anything made by people using our services, user-generated content, and other content. This site ensures use of your data in accordance with our privacy policy and by accessing and using the site you are agreeing to the following terms and conditions set by the organization, and you must exit/leave the site if you do not agree with any of the terms contained herein regarding the Privacy Policy of AAN TV. </p>
<p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification.</p>
<h5>TERMS AND CONDITIONS</h5>
<p>1)	The privacy policy covers the information about AAN TV, all the personal information of the users is collected if the user is registered with our site. The information is saved and only used for the registration purposes.</p>
<p>2)	This document is an electronic record in terms of information and rules that are applicable to the users. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
<p>3)	The content which is viewed on the AAN TV’s site is copyrighted material and shall not be used by any other user without the prior permission of AAN TV.</p>
<p>4)	You acknowledge and agree not to either directly or indirectly use AAN TV’s content from the site, whether it is in the form of video or text. The user shall not download, capture, reproduce, duplicate, archive, distribute, upload, publish, modify, translate, broadcast, perform, display, sell, transmit or retransmit the content or create any derivative work and/or material based on the content.</p>
<p>5)	You agree that your use of the site services and the content may give you the opportunity to view, share, post, and comment in accordance with these terms and conditions.</p>
<p>6)	You agree that you will not misuse the content. In case if the site allows you to download or stream any of the contents therein, you agree that you will not use, sell, transfer, or transmit the content to any person or upload the content on any other website, webpage, or software.</p>
<p>7)	You may have an opportunity to publish, transmit, submit, or otherwise post only comments. You agree that AAN TV shall have the right to delete any comment as needed.</p>
<p>8)	It is AAN TV policy to respect your privacy regarding any information we may collect from you across our site, and other sites we own and operate.</p>
<p>9)	AAN TV only ask for personal information when truly needed to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent and will also let you know why we are collecting it and how it will be used.</p>
<p>10)	AAN TV only retain collected information for as long as necessary to provide you with your requested service. What data we store and protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.</p>
<p>11)	AAN TV don’t share any personally identifying information publicly or with third parties, except when required to by law.</p>
<p>12)	Our website may link to external sites that are not operated by us. Please be aware that AAN TV have no control over the content and practices of these sites and cannot accept responsibility or liability for their respective privacy policies.</p>
<p>13)	You are free to refuse our request for your personal information, with the understanding that AAN TV may be unable to provide you with some of your desired services.</p>
<p>14)	Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information.</p>
<h5>INFORMATION HANDLING</h5>
<p>When you access or use the site, AAN TV automatically collect and store information like the following concerning your visit.</p>
<p>1)	Log and usage information related to your visit, including the type of browser you use, application version, access times, pages viewed, and if you linked to the site from another website, the address of such site.</p>
<p>2)	Device information for the computer or mobile device you use to access the site, including the hardware model, operating system and version, unique device identifiers, and mobile network information.</p>
<p>3)	Information from cookies and similar technologies. We use cookies, web beacons, and other technologies to collect information about your visit and use of the site. For more information about cookies and how to disable them if desired, see the cookies and analytics section that follows.</p>
<h5>COOKIES</h5>
<p>AAN TV use cookies, web beacons, and similar technologies for automated data collection and tracking of your usage of the site. Cookies consist of a small file placed on the hard drive of your computer. Web beacon are electronic images that may be used on our site that help deliver cookies, count visits, and understand usage of the site. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you elect this setting, you may be unable to access certain portions of the site. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our site. Some portions of the site require the use of cookies for full functionality.</p>
<h5>YOUR INFORMATION</h5>
<p>Except for information collected and stored automatically described above, AAN TV do not collect your personally identifiable information on the site unless you choose to provide it to us. If you provide us with personally identifiable information by, for example, sending us an email or filling out a form and submitting it through the site, we use that information to respond to your message and to help us provide you with the information and services that you request. When you submit information voluntarily through the site, you are indicating your voluntary consent to use the information you submit. In addition to information collected and stored automatically, the information AAN TV collect on or through our site may include.</p>
<p>1)	Information that you provide by filling in forms on our site.</p>
<p>2)	Documents such as correspondence or resumes that you submit through the site and</p>
<p>3)	You search queries, if any, on the site.</p>
<h5>HOW AAN TV USE YOUR INFORMATION</h5>
<p>AAN TV use personal information to provide you with information, services or products, to verify identities and communicate with you, to provide services to You, and others, to comply with our legal obligations, and to provide safety and security. </p>
<p>AAN TV use information that we collect about you or that you provide to us, including any personal information for various purposes, including to:</p>
<p>1)	Present our site and its content to you, and to notify you about changes to the site</p>
<p>2)	Provide you with information, services, or products that you request from AAN TV</p>
<p>3)	Verify your identity</p>
<p>4)	Fulfill any other purpose for which you provide it</p>
<p>5)	Carry out our obligations and enforce our rights arising from any contracts involving you and AAN TV, as applicable</p>
<p>6)	To provide safety and security. </p>

<h5>DISCLOSURE OF YOUR INFORMATION</h5>
<p>AAN TV share personal information with clients for which we provide services and with vendors, service providers and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and secure, and as necessary to comply with our legal and contractual obligations.</p>
<p>AAN TV may disclose information about our users and information that does not identify any individual without restriction and may disclose personal information that we collect, or you provide as described in this policy:</p>
<p>1)	To fulfill the purpose for which you provide it</p>
<p>2)	To vendors, service providers and other third parties AAN TV use to support our business and who are bound by contractual obligations to keep personal information confidential and secure, and to use it only for the purposes for which AAN TV disclose it to them. </p>
<p>3)	To our clients for which AAN TV serve as a contractor or service provider</p>
<p>4)	To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of AAN TV’s assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which personal information held by AAN TV is among the assets transferred</p>
<p>5)	To our affiliates and subsidiaries that are also covered by this policy or that provide substantial similar privacy protection.</p>

<h5>AAN TV may also disclose your personal information:</h5>

<p>1)	To comply with any court order, law or legal process, including to respond to government or regulatory requests</p>
<p>2)	To comply with and enforce legal obligations, including assessing compliance with regulatory requirements and to enforce or apply our terms of use and other agreements, including for billing and collection purposes. </p>
<p>3)	If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of AAN TV, our customers, or others.</p>

<h5>RETENTION OF INFORMATION</h5>
<p>AAN TV retain information until it is no longer needed for the purpose for which it has been provided unless we are required to keep it longer by law or policy. </p>
<p>We destroy the information collected when the purpose for which it was provided has been fulfilled unless AAN TV is required to keep it longer by law or official policy. In some cases information that we receive or collect as a contractor or service provider for a client may become an agency record and may be subject to a freedom of information act or similar request directed to that client.</p>
<h5>SECURITY MEASURES</h5>
<p>Although transmission of information electronically is never completely secure, AAN TV protect your personal information through the implementation of commercially reasonable security measures designed to protect against accidental loss of information and from unauthorized access, use, alteration, and disclosure.</p>
<p>AAN TV have implemented commercially reasonable security measures designed to protect your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. However, the safety and security of your information also depends on you. Where AAN TV have given you (or where you have chosen) a password for access to certain parts of our site, you are responsible for keeping this password confidential and we ask you not to share your password with anyone. Unfortunately, the transmission of information via the Internet is not completely secure. Although AAN TV take commercially reasonable measures to protect your personal information, we cannot guarantee the security of information transmitted to our site. Any transmission of personal information is at your own risk. AAN TV is not responsible for circumvention of any privacy settings or security measures contained on the site.</p>
<p>For site security purposes and to ensure that this service remains available to all users, AAN TV’s systems employ software programs to monitor network traffic to identify unauthorized attempts to upload or change information, or otherwise cause damage. If such monitoring reveals evidence of possible abuse or criminal activity, such evidence may be provided to appropriate law enforcement officials. Unauthorized attempts to upload or change information on this server are strictly prohibited and may be punishable under governing law of Pakistan.</p>

<h5>LINKS FROM THIRD PARTY SITES</h5>
<p>Our site contain links to various federal agencies and private organizations, and other sites not associated with us may link to the site. AAN TV is not responsible for the privacy practices employed by such third-party websites or pages that have links to or from the site. Once you link to another site, you are then subject to the privacy policies of the new site. It is always prudent to read the privacy policy of any site you visit.</p>

<h5>ACCESSING AND CORRECTING YOUR INFORMATION</h5>
<p>You can review and change certain personal information by logging onto the site and visiting your account profile page.  You can also contact us to request revisions. With respect to those portions of the site where your personal information may be stored, you can review and change your personal information by logging into the site and visiting your account profile page.</p>
<p>You may also send us an e-mail to request access to, correct or delete any personal information that you have provided to us. AAN TV cannot delete your personal information except by also deleting your user account and may not accommodate a request to change information if AAN TV believe the change would violate any law or legal requirement or cause the information to be incorrect. Proper access and use of information provided on the Websites is governed by our Terms of Use.</p>

<h5>CHILDREN</h5>
<p>The Websites are intended for use only by adult residents of Pakistan. If you are not 18 years of age or older, you are not permitted to use AAN TV’s site.  We do not knowingly collect personal information from children under the age of eighteen (18) years.  If you think that we have collected information from a child under the age of eighteen through our site, please contact us.  If we learn that we have collected the information of a child under the age of eighteen years, AAN TV will take reasonable steps to delete such information.</p>

<h5>CHANGES IN PRIVACY POLICY</h5>
<p>From time-to-time AAN TV may update this privacy policy.</p>

<h5>CONTACT INFORMATION</h5>
<p>To ask questions or comment about this policy and our privacy practices, contact us at: </p>
<p>Address: MGC Complex, G8 Markaz Islamabad.</p>
<p>Email: <a href="mailto:info@aan.tv" >info@aan.tv</a></p>
    </div>
  </div>
  );
}