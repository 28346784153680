import React, { Component } from "react";
import "./MiddleSlider.scss"
import SingleMiddleSlider from "../SingleMiddleSlider/SingleMiddleSlider";
import SingleMiddleLandSlider from "../SingleMiddleLandSlider/SingleMiddleLandSlider";


export default class MiddleSlider extends Component {


  render() {
    return (
      <div className="single-middle-slider">
        <div >
            {
                // this.props.content.map((item, index) => <SingleMiddleSlider name={item.name} data={item.data} key={`list-${index}`} />)
            }
            {
              this.props.content.vertical == 1 ?  <SingleMiddleSlider vertical={this.props.content.vertical} name={this.props.content.name} contents={this.props.content.data} />
              :  <SingleMiddleLandSlider vertical={this.props.content.vertical} name={this.props.content.name} contents={this.props.content.data} />
            }
           
        </div>
      </div>
    );
  }
}