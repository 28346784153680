import "./Loader.scss";
import logo from "../../../images/AAN-LOGO-GIF.gif";

export default function Loader() {

  return (
    <div className="loader-section">
    <img src={logo} alt="Logo" />
  </div>
  );
}
