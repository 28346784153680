import React from "react";
import ReactModal from "react-modal";
import ModalSearch from "../ModalSearch/ModalSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import "./SearchModal.scss";

export default class SearchModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        showModal: false
      };

      this.handleOpenModal = this.handleOpenModal.bind(this);
      this.handleCloseModal = this.handleCloseModal.bind(this);

  }
  
  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }

  render() {
    return (
        <div>
        {/* <button onClick={this.handleOpenModal} className="search-icon"><FontAwesomeIcon icon={faSearch}  /></button> */}
      <div onClick={this.handleOpenModal} className="search-box">
      <div
          className="search-input"
        >Search</div>

        <div className="search-icon">
        <FontAwesomeIcon icon={faSearch}  />
        </div>
      </div>
        <ReactModal 
           isOpen={this.state.showModal}
           contentLabel="onRequestClose"
           onRequestClose={this.handleCloseModal}
           className="Modal"
           ariaHideApp={false}
           overlayClassName="Overlay"
        >
          <ModalSearch onClick={this.handleCloseModal} />
          {/* <button onClick={this.handleCloseModal}>Close Modal</button> */}
        </ReactModal>
      </div>
    );
  }
}
