import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
import "./SingleMiddleLandSlider.scss"
import background from "../../../../images/flower-final.png";
import divider from "../../../../images/divider-icon.png";
import SingleMiddleSliderItem from "../SingleMiddleSliderItem/SingleMiddleSliderItem";

export default class SingleMiddleLandSlider extends Component {

  constructor(props) {
    super(props);
    this.state = { isHover: false };
  }

  render() {
    var settings = {
      dots: false,
      infinite: false,
      arrows:true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 787,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="slick-horizontal">
        <div className="slider-heading-middle" >
        <div 
                      onMouseEnter={() => this.setState({isHover: true})}
                      onMouseLeave={() => this.setState({isHover: false})}
        style={{ backgroundImage: `url(${background})` }}
        className="flower"
        >
            <h2

            > {this.props.name} </h2>
            <img src={divider} />
            <p className={this.state.isHover ? 'explore-new show-text': 'explore-new hide-text'}>Explore all</p>
            {
              // this.state.isHover ?  <p className="explore-new">Explore all</p> : null
            }
            {/* <p className="explore">Explore all</p> */}
        </div>
        </div>
        <Slider {...settings}>
        {this.props.contents.map((item, i) => (
            <SingleMiddleSliderItem vertical={this.props.vertical} key={`listItem-${i}`} index={i} item={item} />
          ))}
        </Slider>
      </div>
    );
  }
}